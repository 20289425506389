/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { Box, Flex, Text } from '@fivehealth/botero';
import BotCryingAvatar from '../assets/images/bot-crying-avatar.svg';

const ErrorAccordion = ({ name, value }) => {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <Box mt={2} mb={2} scrollBehavior="smooth">
      <Text
        textOverflow="ellipsis"
        maxWidth="30ch"
        overflow="hidden"
        whiteSpace="nowrap"
        key={name}
        onClick={() => setShowDescription(!showDescription)}
      >
        {name}
      </Text>
      {showDescription ? (
        <Text
          textOverflow="ellipsis"
          maxWidth="30ch"
          overflow="hidden"
          whiteSpace="nowrap"
          key={name}
        >
          {value}
        </Text>
      ) : null}
    </Box>
  );
};

const getDescription = (description, errorObj) => {
  const descriptionText = description;
  return (
    <Box m="18px">
      <Text
        color="fullShade"
        fontSize="16px"
        fontWeight="600"
        textAlign="center"
        scrollBehavior="smooth"
      >
        {descriptionText}
      </Text>
      <Text
        mt={2}
        color="darkestShade"
        fontSize="14px"
        fontWeight="400"
        textAlign="center"
        lineHeight="24px"
      >
        If the problem persists, please contact
        <a
          style={{
            marginLeft: '6px',
            textDecoration: 'underline',
            color: '#256BF6',
            lineHeight: '24px',
          }}
          href="mailto:customersupport@botmd.io"
        >
          customer support
        </a>
        .
      </Text>
      {errorObj && (
        <Box mt={2} mb={2} scrollBehavior="smooth">
          {Object.keys(errorObj).length &&
            Object.keys(errorObj).map((k) => (
              <ErrorAccordion name={k} value={errorObj[k]} />
            ))}
        </Box>
      )}
    </Box>
  );
};

const ErrorPage = ({ description = '', errorObj = null }) => (
  <Flex
    width="100%"
    paddingTop="180px"
    justifyContent="center"
    alignItems="center"
  >
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="504px"
    >
      <Box as="img" src={BotCryingAvatar} mb={2} width="104px" />
      {getDescription(description, errorObj)}
    </Flex>
  </Flex>
);

export default ErrorPage;
